define("discourse/plugins/discourse-private-topics/discourse/templates/connectors/category-custom-security/private-topics", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.private_topics_enabled}}
    <section>
      <h3>{{i18n "category.private_topics.title"}}</h3>
    </section>
    <section class="field category_private_topics_enabled">
      <label>
        <Input
          @type="checkbox"
          @checked={{this.args.outletArgs.category.custom_fields.private_topics_enabled}}
        />
        {{i18n "category.private_topics.enabled"}}
      </label>
    </section>
  
    {{#if this.args.outletArgs.category.custom_fields.private_topics_enabled}}
      <section class="field">
        <label>
          {{i18n "category.private_topics.allowed_groups_description"}}
        </label>
        <div class="value">
        <GroupChooser
          @content={{this.availableGroups}}
          @valueProperty={{null}}
          @nameProperty={{null}}
          @value={{this.selectedGroups}}
          @onChange={{this.onChangeGroups}}
        />
        </div>
      </section>
    {{/if}}
  {{/if}}
  */
  {
    "id": "5GTllL4X",
    "block": "[[[41,[30,0,[\"siteSettings\",\"private_topics_enabled\"]],[[[1,\"  \"],[10,\"section\"],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,[28,[35,1],[\"category.private_topics.title\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"section\"],[14,0,\"field category_private_topics_enabled\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"args\",\"outletArgs\",\"category\",\"custom_fields\",\"private_topics_enabled\"]]]],null],[1,\"\\n      \"],[1,[28,[35,1],[\"category.private_topics.enabled\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"args\",\"outletArgs\",\"category\",\"custom_fields\",\"private_topics_enabled\"]],[[[1,\"    \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"category.private_topics.allowed_groups_description\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"value\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@content\",\"@valueProperty\",\"@nameProperty\",\"@value\",\"@onChange\"],[[30,0,[\"availableGroups\"]],null,null,[30,0,[\"selectedGroups\"]],[30,0,[\"onChangeGroups\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"if\",\"i18n\",\"input\",\"group-chooser\"]]",
    "moduleName": "discourse/plugins/discourse-private-topics/discourse/templates/connectors/category-custom-security/private-topics.hbs",
    "isStrictMode": false
  });
});